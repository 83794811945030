import { Navigate, Outlet } from "react-router-dom";

export default function HandlePrivateRoutes({ data: { jwtToken } }) {
  let component;
  let isAuthenticated = false;

  if (jwtToken) {
    isAuthenticated = true;
  }
  if (isAuthenticated) {
    component = <Outlet />;
  } else {
    component = <Navigate to="/" />;
  }
  return component;
}
