import { HalamanUploadMemberArea } from "pages/HalamanAdmin/HalamanUploadMemberArea/Loadable";
import { HalamanUploadVideos } from "pages/HalamanAdmin/HalamanUploadVideos/Loadable";
import { HalamanMonitoringMember } from "pages/HalamanAdmin/HalamanMonitoringMember/Loadable";
import { HalamanUploadArticles } from "pages/HalamanAdmin/HalamanUploadArticles/Loadable";
import { HalamanUploadEbooks } from "pages/HalamanAdmin/HalamanUploadEbook/Loadable";
import { HalamanSettings } from "pages/HalamanSettings/Loadable";

export const privateAdminRoutes = [
  {
    path: "/uploadvideo",
    element: <HalamanUploadVideos />,
  },
  {
    path: "/uploadfile",
    element: <HalamanUploadEbooks />,
  },
  {
    path: "/uploadarticles",
    element: <HalamanUploadArticles />,
  },
  {
    path: "/uploadmemberarea",
    element: <HalamanUploadMemberArea />,
  },
  {
    path: "/monitoringmember",
    element: <HalamanMonitoringMember />,
  },
  {
    path: "/settings",
    element: <HalamanSettings />,
  },
];
