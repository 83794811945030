import { HalamanArticle } from "pages/HalamanArticle/Loadable";
import { HalamanLogin } from "pages/HalamanLogin/Loadable";
import { HalamanLoginAdmin } from "pages/HalamanLoginAdmin/Loadable";
import { HalamanProfileOwner } from "pages/HalamanProfileOwner/Loadable";
import { HalamanRegister } from "pages/HalamanRegister/Loadable";

export const publicRoutes = [
  {
    path: "/",
    element: <HalamanLogin />,
  },
  {
    path: "/befin-admin",
    element: <HalamanLoginAdmin />,
  },
  {
    path: "/gagal",
    element: <HalamanLogin />,
  },
  {
    path: "/success",
    element: <HalamanLogin />,
  },
  {
    path: "/article",
    element: <HalamanArticle />,
  },
  {
    path: "/refCode/:refCode",
    element: <HalamanRegister />,
  },
  {
    path: "/signup",
    element: <HalamanRegister />,
  },
  {
    path: "/signout",
    element: <HalamanLogin />,
  },
  {
    path: "/profile",
    element: <HalamanProfileOwner />,
  },

  {
    path: "*",
    element: <h1>404 - Page Not Found</h1>,
  },
];
