import {
  HalamanMemberArea,
  IndexEnableAcces,
} from "pages/HalamanMember/Loadable";
import { HalamanSettings } from "pages/HalamanSettings/Loadable";

export const privateRoutes = [
  {
    path: "/settings",
    element: <HalamanSettings />,
  },
  {
    path: "/memberArea",
    element: <HalamanMemberArea />,
  },
  {
    path: "/accessvideoebook",
    element: <IndexEnableAcces />,
  },
];
