import CryptoJS from "crypto-js";

export function SimpleDecrypt(msg = "", strKey = "") {
  if (msg === null || msg === "") {
    return null;
  }
  const pass = CryptoJS.enc.Utf8.parse(strKey);
  const ciphertext = CryptoJS.enc.Base64.parse(msg);
  const iv = ciphertext.clone();
  iv.sigBytes = 4;
  iv.clamp();
  ciphertext.words.splice(0, 1);
  ciphertext.sigBytes -= 4;
  let decrypted = CryptoJS.AES.decrypt({ ciphertext }, pass, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  try {
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
  } catch (e) {}

  return decrypted;
}

export function SimpleEncrypt(msg, strKey) {
  if (msg === null || msg === "") {
    return null;
  }
  const pass = CryptoJS.enc.Utf8.parse(strKey);
  const plainText = CryptoJS.enc.Utf8.parse(msg);
  const iv = CryptoJS.lib.WordArray.random(32 / 8);
  const encrypted = CryptoJS.AES.encrypt(plainText, pass, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  const resultCiphertext = iv
    .concat(encrypted.ciphertext)
    .toString(CryptoJS.enc.Base64);
  return resultCiphertext;
}
