import React from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { publicRoutes } from "./routes/PublicRoutes";
import HandlePrivateRoutes from "./routes/HandlePrivateRoutes";
import { privateRoutes } from "routes/PrivateRoutes";
import { SimpleDecrypt } from "utils/EnDecrypt";
import { SECRET_KEY } from "pages/HalamanLogin/Constants";
import { privateAdminRoutes } from "routes/PrivateAdminRoutes";

function App(props) {
  const loginData = JSON.parse(
    SimpleDecrypt(sessionStorage.getItem(SECRET_KEY), "")
  );

  return (
    <Router>
      <Routes>
        {publicRoutes.map((item, index) => {
          return <Route key={index} path={item.path} element={item.element} />;
        })}

        {loginData?.user?.role === "user" &&
          privateRoutes.map((item, index) => {
            return (
              <Route
                key={index}
                element={
                  <HandlePrivateRoutes
                    data={{ jwtToken: loginData?.access_token || "" }}
                  />
                }
              >
                <Route path={item.path} element={item.element} />
              </Route>
            );
          })}
        {loginData?.user?.role === "admin" &&
          privateAdminRoutes.map((item, index) => {
            return (
              <Route
                key={index}
                element={
                  <HandlePrivateRoutes
                    data={{ jwtToken: loginData?.access_token || "" }}
                  />
                }
              >
                <Route path={item.path} element={item.element} />
              </Route>
            );
          })}
      </Routes>
    </Router>
  );
}

export default App;
